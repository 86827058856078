
































































import Vue from 'vue';
import { Tabs, FormType } from '../constant';
import Wechat from './wechat.vue';
import Code from './code.vue';
import Password from './password.vue';
import Bus from '~/components/bus';
import i18n from '~/plugins/i18n';

export default Vue.extend({
  name: 'login',
  components: {
    'ju-wechat': Wechat,
    'ju-code': Code,
    'ju-password': Password,
  },
  props: {
    formTypeChange: Function,
    source: String,
  },
  data(): any {
    // console.dir(Vue.$t);
    // console.log('94-94', this.$nuxt.$t('menu.home')); //  (Vue as any).$t
    // debugger;
    return {
      Tabs,
      FormType,
      tabs: [
        {
          // @ts-ignore
          label: this.$nuxt.$t('login.scanCodeLogin'), //'扫码登录', //this.$t('login.scanCodeLogin'), //this.$nuxt.$t('login.scanCodeLogin'),
          value: Tabs.wechat,
        },
        {
          // @ts-ignore
          label: this.$nuxt.$t('login.verificationCodeLogin'),
          value: Tabs.code,
        },
        {
          // @ts-ignore
          label: this.$nuxt.$t('login.passwordLoginText'),
          value: Tabs.pwd,
        },
      ],
      tabType: Tabs.code,
    };
  },
  mounted() {
    Bus.$on('getTabType', () => {
      Bus.$emit('sendTabType', this.tabType);
    });
  },
  methods: {
    handleTabChange(type: Tabs) {
      this.$data.tabType = type;
      if (type === Tabs.wechat) {
        this.$nextTick(() => {
          (this.$refs.wechatRef as any)?.handleGetWxQRCode();
        });
      }
    },
    handleFormTypeChange(type: FormType) {
      this.$props.formTypeChange(type);
    },
  },
});
