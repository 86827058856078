import { render, staticRenderFns } from "./wechat.vue?vue&type=template&id=0d5ca34c&scoped=true&"
import script from "./wechat.vue?vue&type=script&lang=ts&"
export * from "./wechat.vue?vue&type=script&lang=ts&"
import style0 from "./wechat.vue?vue&type=style&index=0&id=0d5ca34c&lang=less&module=true&scoped=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "0d5ca34c",
  null
  
)

export default component.exports