





























import Vue from 'vue';
import { FormType, Tabs } from './constant';
import Register from './component/register.vue';
import Bind from './component/bind.vue';
import Forget from './component/forget.vue';
import Login from './component/login.vue';
import Bus from '~/components/bus';

export default Vue.extend({
  layout: 'loginLayout',
  components: {
    'ju-login': Login,
    'ju-forget': Forget,
    'ju-register': Register,
    'ju-bind': Bind,
  },
  data() {
    return {
      Tabs,
      FormType,
      formType: FormType.login,
    };
  },
  watch: {
    formType: {
      handler(val) {
        console.log('56-56', val);
        if (val === FormType.login) {
          this.$nextTick(() => {
            Bus.$emit('getTabType');
          });
        }
      },
      immediate: true,
    },
  },
  created() {
    Bus.$on('sendTabType', (val: number) => {
      console.log('67-67', val, Tabs.wechat);
      if (val === Tabs.wechat) {
        Bus.$emit('fetchWxQRCode');
      }
    });
  },
  methods: {
    handleFormTypeChange(type: FormType) {
      this.$data.formType = type;
    },
    showInfo() {
      this.$completeInfo.show();
    },
  },
  head() {
    return {
      title: '登录-聚展',
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
});
