
























import Vue from 'vue';
import _ from 'lodash';
import { FormType } from '../constant';
import Bus from '~/components/bus';
import { $axios, $cookies, $store } from '~/util/api';
import { ResponseCode } from '~/util/constant';

let timer: NodeJS.Timeout;
const expiresTime: Number = 60; // 二维码过期时间
export default Vue.extend({
  props: {
    formTypeChange: Function,
  },
  data(): Record<string, any> {
    return {
      wechat: {},
      time: expiresTime,
    };
  },
  created() {
    if (process.client) {
      Bus.$on('fetchWxQRCode', () => {
        this.handleGetWxQRCode();
      });
    }
  },
  beforeDestroy() {
    if (timer) {
      this.time = 0;
      clearTimeout(timer);
    }
  },
  methods: {
    async handleWxLogin() {
      const { ticket } = this.wechat;
      if (!ticket) {
        console.log('ticket is undefined');
        return;
      }
      const res: any = await $axios.get(`/api/auth/wxlogin/${ticket}`);
      if (res.code === ResponseCode.NO_CODE_SCANNING) {
        if (this.time > 0) {
          timer = setTimeout(() => {
            this.handleWxLogin();
            clearTimeout(timer);
            this.time--;
          }, 1000);
        }
      } else if (res.code === ResponseCode.UNREGISTERED && res.data.openId) {
        clearTimeout(timer);
        this.$props.formTypeChange(FormType.bind);
        $cookies.set('openId', res.data.openId, {
          expires: new Date(Date.now() + 7200000),
        });
      } else if (res.code === ResponseCode.SUCCESS) {
        clearTimeout(timer);
        const access_token = _.get(res, 'data.jwtToken.access_token', '');
        // const expires = _.get(res, 'data.jwtToken.expires_in', 0);

        $cookies.set('access_token', access_token, {
          maxAge: 60 * 60 * 24 * 7,
          path: '/',
        });
        // 初始化
        await $store.commit('user/setOrigins', '');
        await $store.commit('user/setIndustryId', 0);
        await $store.commit('user/setAction', 0);
        await $store.commit('global/setIsLogin', true);
        await $store.dispatch('global/getUser');

        const redirect = _.get(this, '$route.query.redirect');
        this.$login.hide();
        if (!$store.state.global.user.isPererffect) {
          try {
            (this.$parent?.$parent as any)?.showInfo();
          } catch (e) {
            (this.$parent?.$parent?.$parent as any)?.showInfo();
          }
        } else if (redirect) {
          location.href = redirect;
        }
      }
    },
    async handleGetWxQRCode() {
      this.time = expiresTime;
      const res: any = await $axios.get('/api/auth/getWxQRScanCode');
      if (res.code === ResponseCode.SUCCESS) {
        this.$data.wechat = res.data;
        if (process.client) {
          this.$nextTick(() => {
            this.handleWxLogin();
          });
        }
      }
    },
  },
});
